import React from 'react';
import styles from './Header.module.css';

const Header = ({ setSearchPerformed, setInitialQuery, logo }) => {

  const hideSearchModal = () => {
    setInitialQuery('');
    setSearchPerformed(false);
  }

  return(
    <div onClick={hideSearchModal} className={styles.wrapper}>
      <div className={styles.logo}>
        <img className={styles.logo_item} src={logo} alt='logo'/>
      </div>
      <div className={styles.buttons_wrapper}>
        <a href='/users/sign_in' className={styles.signIn_button}>Sign In</a>
        <a href='/users/sign_up' className={styles.signUp_button}>Sign Up</a>
      </div>
    </div>
  );
}

export default Header;