import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '../util/misc';
import Tree from 'react-d3-tree';

const TreeGraph = ({data}) => {

  const handleCompanyClick = (data, toggleNode) => {
    if (data.type === "COMPANY") {
      props.dispatch(fetchCompany(data.id));
      setDetailsVisible(true);
    } else {
      toggleNode()
    }
  }

  const displayNodeTitle = data => {
    const isCompany = data.type === "COMPANY";
    if (!isCompany) {
      return <>{data.name}</>
    }

    else {
      return (
        <div style={{display: 'flex', borderBottom: '1px 000 solid'}}>
          { data.logo && 
            <img
            className="w-8 h-8 mr-2"
            src={data.logo}
            alt=""
            />
          }
          <h3>{data.name}</h3>
        </div>
      )
    }
  }


  const nodeSize = { x: 200, y: 50 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20, y: -12 };
  const rootProps = { width: 200, height: 200, x: -200, y: -100 };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps
  }) => {

    const isRoot = nodeDatum.name === 'ROOT';
    const isCompany = nodeDatum.type === "COMPANY";
    const fops = isRoot ? rootProps: foreignObjectProps
    
    return (
      <g>
        <circle r={1}></circle>
        {/* `foreignObject` requires width & height to be explicitly set. */}
        <foreignObject {...fops}>
          <div style={{ display: 'flex'}} onClick={() => handleCompanyClick(nodeDatum, toggleNode)}>
            <h3 style={{ paddingRight: '10px', paddingLeft: '10px'}}>
              {isRoot 
              ?
                <img src={map.root_image_url} style={{ width: '100%'}} />
              :
                displayNodeTitle(nodeDatum)
              }
            </h3>
            
          </div>
        </foreignObject>
      </g>
    )
  };

  
  return (
    <Tree 
      translate={treeTranslate}
      data={data}
      onNodeClick={handleCompanyClick}
      initialDepth={1}
      shouldCollapseNeighborNodes={true}
      separation={{nonSiblings: 1, siblings: 1}}
      renderCustomNodeElement={(rd3tProps) =>
        renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
      }
      nodeSize={nodeSize}
      />
  );
}


export default TreeGraph