import {
  FETCH_MAP_BEGIN,
  FETCH_MAP_SUCCESS,
  FETCH_MAP_FAILURE,
  SEARCH_COMPANY_BEGIN,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_FAILURE,
  SEARCH_COMPANY_CLEAR,
} from '../actions/mapActions';

const initialState = {
  map: null,
  loading: false,
  error: null,
  success: null,
  searchItems: [],
  searchLoading: false,
  searchError: null,
};

export default function mapReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_MAP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        map: action.payload.map,
      };

    case FETCH_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SEARCH_COMPANY_BEGIN:
      return {
        ...state,
        searchLoading: true,
        error: null
      };

    case SEARCH_COMPANY_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        searchItems: action.payload.results,
      };

    case SEARCH_COMPANY_FAILURE:
      return {
        ...state,
        searchLoading: false,
        searchError: action.payload.error,
        searchItems: []
      };

    case SEARCH_COMPANY_CLEAR:
      return {
        ...state,
        searchItems: [],
      };

    default:
      return state;
  }
}