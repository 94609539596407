import {
  FETCH_FORMATTED_MAP_BEGIN,
  FETCH_FORMATTED_MAP_SUCCESS,
  FETCH_FORMATTED_MAP_FAILURE,
  FETCH_FORMATTED_MAP_CLEAR,
} from '../actions/ecosystemActions';

const initialState = {
  formattedMap: null,
  loading: false,
  error: null,
  success: null,
};

export default function ecosystemReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_FORMATTED_MAP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_FORMATTED_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        formattedMap: action.payload.map,
        success: true,
      };

    case FETCH_FORMATTED_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_FORMATTED_MAP_CLEAR:
      return {
        ...state,
        success: false,
      };

    default:
      return state;
  }
}