import React from "react"
import Drawer from './Drawer';
import CategoryBreadcrumbsDisplay from './CategoryBreadcrumbsDisplay';

const IMAGE_DATA = {
  website: {
    image_url: 'https://meshconomy.s3.us-east-2.amazonaws.com/assets/www+icon+.png',
    alt: 'Website'
  },
  linked_in: {
    image_url: 'https://meshconomy.s3.us-east-2.amazonaws.com/assets/linkedin+icon+.png',
    alt: 'LinkedIn'
  },
  crunchbase: {
    image_url: 'https://meshconomy.s3.us-east-2.amazonaws.com/assets/crunchbase+logo.png',
    alt: 'Crunchbase'
  },
  pitchbook: {
    image_url: 'https://meshconomy.s3.us-east-2.amazonaws.com/assets/pitchbook+logo.png',
    alt: 'PitchBook'
  }
}

const CompanyDetails = ({ visible, onClose, company, loading, color}) => {
  
  const title = loading || company == null ? 'Loading...' : company.name

  const displaySocialImageLink = (key, url) => {
    return (
      <a href={url} target="_blank">
        <img src={IMAGE_DATA[key].image_url} alt={IMAGE_DATA[key].alt} className="h-12" />
      </a>
    )
  }
  return (
    <Drawer visible={visible} onClose={onClose}>
      <div className="flex-1 h-0 overflow-y-auto">
        <Drawer.Title title={title} onClose={onClose} color={color}/>
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <div className="pb-1 sm:pb-6">
              <div>
                { company && !loading && 
                  <div className="flex justify-center p-4">
                    <img
                      className="h-32"
                      src={company.logo}
                      alt=""
                      />
                  </div>
                }
              </div>
            </div>
            <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
              { company && !loading
              ?
                <>
                  <p className="text-sm text-gray-600 truncate space-y-1 px-4 sm:px-6">
                    {company.category_trees.map(ct => <CategoryBreadcrumbsDisplay categories={ct} />)}
                  </p>
                  <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">About</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                        <p>
                          {company.about}
                        </p>
                      </dd>
                    </div>
                    { company.contact_name && 
                      <div>
                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Contact name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{company.contact_name}</dd>
                      </div>
                    }
                      <div className="flex space-x-4">
                        { company.website && displaySocialImageLink('website', company.website) }
                        { company.linked_in_url && displaySocialImageLink('linked_in', company.linked_in_url) }
                        { company.crunchbase_url && displaySocialImageLink('crunchbase', company.crunchbase_url) }
                        { company.pitchbook_url && displaySocialImageLink('pitchbook', company.pitchbook_url) }
                      </div>
                  </dl>
                </>
              :
              ''
              }
              
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        
      </div>
          
    </Drawer>
  );

}

export default CompanyDetails;