import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { OfficeBuildingIcon, SearchIcon } from '@heroicons/react/solid'
import { searchCompany, searchCompanyClear } from "../actions/mapActions";
import CategoryBreadcrumbsDisplay from './CategoryBreadcrumbsDisplay';
import styles from './MapSearch.module.css';

const empty = 
  <div className="flex items-center h-full justify-center">
    <div className="text-center">
      <h3 className="mt-2 text-sm font-medium text-gray-900">No results found</h3>
      <p className="mt-1 text-sm text-gray-500">We can't find anything with that term. Try searching for something else.</p>
    </div>
  </div>


const SearchBar = ({ setIsInputFilled, setSearchPerformed, isOpen, onSearch, initialQuery, placeholder }) => {
  const [query, setQuery] = useState(initialQuery);
  
  const handleChange = input => {
    setQuery(input);
    onSearch(input);
  }

  useEffect(() => {
    if(query !== '') {
      setSearchPerformed(true);
      setIsInputFilled(true);
    } else {
      setSearchPerformed(false);
      setIsInputFilled(false)
    }
  }, [query])

  useEffect(() => {
    initialQuery === '' && setQuery('');
  },[initialQuery]) 

  return (
    <div className={styles.search_wrapper}>
      <SearchIcon className={!isOpen ? styles.search_icon : styles.search_icon_z_index} aria-hidden="true" />
      <input
        name="search_field"
        autoComplete="off"
        className={!isOpen ? styles.search_input : styles.search_input_z_index}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        value={query}
        type="text"
      />
    </div>
  )
}

const ResultItem = ({result, onResultClick}) => {
  // const Icon = result.icon;

  return (
    <div className={styles.result_item}>
      <a href={result.url} onClick={e => onResultClick(e, result, result.category_trees)} className="block hover:bg-gray-50">
        <div className="px-4 py-4 flex items-top sm:px-6 text-sm cursor-pointer">
          {/* <Icon className="h-5 w-5 mr-2 mt-1" aria-hidden="true" /> */}
          <div>
            <p className={styles.resultName}>{result.name}</p>
            <div className={styles.resultWay}>
              {result.category_trees.map((ct, index) => <CategoryBreadcrumbsDisplay key={index} categories={ct} onClick={e => onResultClick(e, result, ct)}/>)}
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

const openSearchedCompany = (searchCompanyClear, dispatch, setSearchPerformed, 
                             setIsSearchSelect, result, setCurrentFlow, 
                             setOpenedLevel, setSelectedCompanyNodes, setInitialQuery, setQuery,
                             setIsSelectedBySearch) => {
  setIsSelectedBySearch(result.category_trees);
  setIsSearchSelect(true);
  setCurrentFlow('findCompany');
  setOpenedLevel(null);

  if(result.length > 1) {
    const results_list = result.map(r => 'comp_' + r.id)
    setSelectedCompanyNodes(results_list);
  } else {
    setSelectedCompanyNodes(['comp_' + result.id]);
  }

  setSearchPerformed(false);
  dispatch(searchCompanyClear());
  setInitialQuery('');
  setQuery('');
}

const SearchResults = ({searchCompanyClear, 
                        dispatch, 
                        setSearchPerformed, 
                        setIsSearchSelect, 
                        results, 
                        onResultClick, 
                        loading, 
                        setCurrentFlow, 
                        setOpenedLevel, 
                        setSelectedCompanyNodes,
                        setInitialQuery,
                        setQuery,
                        setIsSelectedBySearch }) => {
  return (
    <div className={styles.search_results}>
      { loading
      ?
        // <LoadingIndicator loading={loading}/>
        <span>Loading...</span>
      :
      <>
        {results.length === 0 
        ?
          <>{empty}</>
        : 
        <ul className="divide-y divide-gray-200">
          { results.map((result, index) => (
          <div key={index} onClick={() => openSearchedCompany(searchCompanyClear, 
                                                              dispatch, 
                                                              setSearchPerformed, 
                                                              setIsSearchSelect, 
                                                              result, 
                                                              setCurrentFlow, 
                                                              setOpenedLevel, 
                                                              setSelectedCompanyNodes,
                                                              setInitialQuery,
                                                              setQuery,
                                                              setIsSelectedBySearch)}>
            <ResultItem result={result} onResultClick={onResultClick}/></div>))}
        </ul>
        }
      </>
      }
      
    </div>
  )
}

const MapSearch = ({ initialQuery, 
                     setInitialQuery, 
                     searchPerformed, 
                     setSearchPerformed, 
                     setIsSearchSelect, 
                     map, 
                     dispatch, 
                     results, 
                     loading, 
                     onResultClick, 
                     setCurrentFlow, 
                     setOpenedLevel, 
                     setSelectedCompanyNodes,
                     isOpen,
                     setIsSelectedBySearch,
                     setIsInputFilled }) => {
  const [query, setQuery] = useState('');

  const placeholderText = `Search for a company in ${map.title}`

  // fetch items
  useEffect(() => {
    if (query !== '') {
      setSearchPerformed(true);
      dispatch(searchCompany(query, map.id));
    } else {
      setSearchPerformed(false);
      dispatch(searchCompanyClear());
    }
  },[query])

  const handleSearch = _.debounce((input) => {
    setQuery(input)
    setInitialQuery(input);
  }, 300);

  const handleResultClick = (e, result, categories) => {
    e.preventDefault();
    onResultClick({...result, categories});
    // setInitialQuery('');
    // setQuery('');
    // setSearchPerformed(false);
    // dispatch(searchCompanyClear());
  }

  const decoratedResults = results.map(r => {
    return {...r, icon: OfficeBuildingIcon}
  })
  
  return (
    <div className="max-w-3xl mx-auto relative">
      <SearchBar setIsInputFilled={setIsInputFilled} setSearchPerformed={setSearchPerformed} isOpen={isOpen} placeholder={placeholderText} initialQuery={initialQuery} onSearch={(e) => handleSearch(e)} />
      { searchPerformed && 
      <div className={styles.search_results_center}>
        <SearchResults searchCompanyClear={searchCompanyClear} 
                       dispatch={dispatch} 
                       setSearchPerformed={setSearchPerformed} 
                       setIsSearchSelect={setIsSearchSelect} 
                       setSelectedCompanyNodes={setSelectedCompanyNodes} 
                       setOpenedLevel={setOpenedLevel} 
                       setCurrentFlow={setCurrentFlow} 
                       results={decoratedResults} 
                       onResultClick={handleResultClick} 
                       loading={loading}
                       setInitialQuery={setInitialQuery}
                       setQuery={setQuery}
                       setIsSelectedBySearch={setIsSelectedBySearch} 
          />
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  results: state.maps.searchItems,
  loading: state.maps.searchLoading,
  error: state.maps.searchError,
});

export default connect(mapStateToProps)(MapSearch);
