import React from "react"
import { RefreshIcon } from '@heroicons/react/solid'

const LoadingIndicator = ({loading }) => {

  return (
    <>
      { loading && 
      <div className="relative h-full">
        <div className="opacity-50 absolute inset-0 z-10 flex place-items-center justify-center">
          <RefreshIcon className="h-8 w-8 animate-spin" aria-hidden="true" />
        </div>
      </div>
      }
    </>
  );
}

export default LoadingIndicator