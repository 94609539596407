import React, { useState } from "react"

const CategorySelect = (props) => {

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showOther, setShowOther] = useState(false);
  const [other, setOther] = useState('');

  const { categories } = props;

  let computedCategory = selectedCategories.map(c => c.title).join(' > ');
  if (showOther) { 
    computedCategory += ` > Other > ${other}`
  }

  const handleChange = (categoryId, level, children) => {
    if (categoryId === 'other') {
      setShowOther(true);
      setSelectedCategories(selectedCategories.slice(0, level));
    } else {
      setShowOther(false);
      setOther('');
      const cat = children.find(c => `${c.id}` === categoryId);
      if (level == 0) {
        setSelectedCategories([cat]);
      } else {
        setSelectedCategories([...selectedCategories.slice(0, level), cat]);
      }
    }
  }

  const renderSelect = (root, level) => {
    const children = root ? root.children : categories;

    if (children.length == 0) {
      return ''
    }

    const initialOptions = children.map(c => (
      <option value={c.id}>{c.title}</option> 
    ))
    return (
      <select
        id="category"
        name="category"
        required
        className="mb-3 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        onChange={e => handleChange(e.target.value, level, children)}
      >
        <option>Select...</option>
        {initialOptions}
        <option value="other">Other...</option>
      </select>
    )
  }

  return (
    
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-3 sm:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Category</label>
        <div className="mt-1 flex-col rounded-md shadow-sm">
          { renderSelect(null, 0)}
          { selectedCategories.map((c, index) => (
            renderSelect(c, index + 1)
          ))}

          { showOther && <input placeholder="Other category" type="text" className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300' required onChange={e => setOther(e.target.value)}/>
          }

          <input type="hidden" name="company_addition_request[category]" value={computedCategory}  />
        </div>
      </div>
    </div>
  );
}


export default CategorySelect