import React from "react"
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import Chart from './Chart'

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

class Map extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <div className="flex justify-center">
          <Chart 
            mapId={this.props.mapId}
            initialCompanyId={this.props.initialCompanyId}
            totalWidth={1000} totalHeight={500}/>
        </div>
      </Provider>
    );
  }
}


export default Map
