import React from "react"
import styles from "./MapOverview.module.css"

const MapOverview = (props) => {
  const { map, onMapSelect } = props;

  return (
    <div className={styles.ecosustem_wrapper}>
      <a onClick={() => onMapSelect(map)}>
        <img src={map.root_image_url} className={styles.ecosystem_image}/>
      </a>
    </div>
  );
}


export default MapOverview