import React, { useState } from "react"
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import Ecosystem from "./Ecosystem";
import Header from "./Header";

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

function App(props) {
  const { maps, initialCompanyId, initialMapId, mainColor, mapColor, mapBoldColor } = props;

  const [searchPerformed, setSearchPerformed] = useState(false);
  const [initialQuery, setInitialQuery] = useState('');

  return (
    <Provider store={store}>
      <Ecosystem 
        maps={maps}
        initialCompanyId={initialCompanyId}
        initialMapId={initialMapId}
        mainColor={mainColor}
        mapColor={mapColor}
        searchPerformed={searchPerformed} 
        setSearchPerformed={setSearchPerformed}
        setInitialQuery={setInitialQuery}
        initialQuery={initialQuery}
        mapBoldColor={mapBoldColor}
      />
    </Provider>
  );
}

export default App;
