import axios from 'axios';

const BASE_API_URL = '/api';

export const FETCH_MAP_BEGIN   = 'FETCH_MAP_BEGIN';
export const FETCH_MAP_SUCCESS = 'FETCH_MAP_SUCCESS';
export const FETCH_MAP_FAILURE = 'FETCH_MAP_FAILURE';

export const SEARCH_COMPANY_BEGIN   = 'SEARCH_COMPANY_BEGIN';
export const SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS';
export const SEARCH_COMPANY_FAILURE = 'SEARCH_COMPANY_FAILURE';
export const SEARCH_COMPANY_CLEAR = 'SEARCH_COMPANY_CLEAR';

export const fetchMapBegin = () => ({
  type: FETCH_MAP_BEGIN
});

export const fetchMapSuccess = (map) => ({
  type: FETCH_MAP_SUCCESS,
  payload: { map }
});

export const fetchMapFailure = error => ({
  type: FETCH_MAP_FAILURE,
  payload: { error }
});

export function fetchMap(id) {
  return dispatch => {
    dispatch(fetchMapBegin());
    return axios.get(`${BASE_API_URL}/maps/${id}.json`)
      .then(json => {
        dispatch(fetchMapSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchMapFailure(error.response.data)));
  };
}

export const searchCompanyBegin = () => ({
  type: SEARCH_COMPANY_BEGIN
});

export const searchCompanySuccess = (results) => ({
  type: SEARCH_COMPANY_SUCCESS,
  payload: { results }
});

export const searchCompanyFailure = error => ({
  type: SEARCH_COMPANY_FAILURE,
  payload: { error }
});

export function searchCompany(query, mapId) {
  return dispatch => {
    dispatch(searchCompanyBegin());
    let queryString = '';
    if (query !== undefined && query !== '') {
      queryString = `query=${query}`
    }
    return axios.get(`${BASE_API_URL}/maps/${mapId}/search_company.json?${queryString}`)
      .then(json => {
        dispatch(searchCompanySuccess(json.data.results));
        return json.data.results;
      })
      .catch(error => dispatch(searchCompanyFailure(error)));
  };
}

export const searchCompanyClear = () => ({
  type: SEARCH_COMPANY_CLEAR
});