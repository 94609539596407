import axios from 'axios';

const BASE_API_URL = '/api';
const TOKEN = '60fd61357cb7b6eda8f31e5dd45a946de57c2996d8b4a24364aa15c9bf109f4a';

export const FETCH_FORMATTED_MAP_BEGIN   = 'FETCH_FORMATTED_MAP_BEGIN';
export const FETCH_FORMATTED_MAP_SUCCESS = 'FETCH_FORMATTED_MAP_SUCCESS';
export const FETCH_FORMATTED_MAP_FAILURE = 'FETCH_FORMATTED_MAP_FAILURE';
export const FETCH_FORMATTED_MAP_CLEAR = 'FETCH_FORMATTED_MAP_CLEAR';

export const fetchFormattedMapBegin = () => ({
  type: FETCH_FORMATTED_MAP_BEGIN
});

export const fetchFormattedMapSuccess = (map) => ({
  type: FETCH_FORMATTED_MAP_SUCCESS,
  payload: { map }
});

export const fetchFormattedMapFailure = error => ({
  type: FETCH_FORMATTED_MAP_FAILURE,
  payload: { error }
});

export function fetchFormattedMap(id) {
  return dispatch => {
    dispatch(fetchFormattedMapBegin());
    return axios.get(`${BASE_API_URL}/formatted_maps/${id}.json?token=${TOKEN}`)
      .then(json => {
        dispatch(fetchFormattedMapSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchFormattedMapFailure(error.response.data)));
  };
}

export const fetchFormattedMapClear = () => ({
  type: FETCH_FORMATTED_MAP_CLEAR
});