import React, { useState, useRef, useEffect } from "react"
import MapOverview from './MapOverview'
import MapSearch from './MapSearch'
import ForceGraphMap from './ForceGraphMap'
import { connect } from "react-redux";
import { fetchFormattedMap, fetchFormattedMapClear } from "../actions/ecosystemActions";
import styles from './MapOverview.module.css';
import bag from '../images/bag.svg';

 const Ecosystem = ({ searchPerformed, setSearchPerformed, initialQuery, setInitialQuery, maps, dispatch, initialCompanyId, success, map, initialMapId }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [currentFlow, setCurrentFlow] = useState('openLevel');
  const [openedLevel, setOpenedLevel] = useState(null);
  const [selectedCompanyNodes, setSelectedCompanyNodes] = useState([]);
  const [isSelectedBySearch, setIsSelectedBySearch] = useState(null);
  const [selectedMap, setSelectedMap] = useState();
  const selectedId = selectedMap ? selectedMap.id : ''
  const [selectedCompanyId, setSelectedCompanyId] = useState(initialCompanyId);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchSelect, setIsSearchSelect] = useState(false);
  const [isInputFilled, setIsInputFilled] = useState(false);

  const mainColor = 'red';

  const [data, setData] = useState();

  useEffect(() => {
    const selected = calculateInitialMap();
    setSelectedMap(selected);
    getMapData(selected.id);
  }, [maps])
  
  const calculateInitialMap = () => {      
    if (maps.length < 1) {
      return null;
    }

    if (initialMapId) {
      return maps.find(e => e.id === initialMapId);
    } else {
      return maps[0];
    }
  }

  setTimeout(() => {
    setIsSearchSelect(false);
  }, 200);

  useEffect(() => {
    if (success && map) {
      setData(map);
      setIsLoading(false);
      dispatch(fetchFormattedMapClear());
    }
  }, [success])

  const containerRef = useRef(null)

  const selectMap = map => {
    setSelectedMap(map);
    getMapData(map.id);
    containerRef.current.scrollIntoView()
  }

  const getMapData = (id) => {
    setCurrentFlow('openLevel');
    setSelectedCompanyNodes([]);
    setOpenedLevel(null);
    setIsOpen(false);
    setSearchPerformed(false);
    setInitialQuery('');
    setIsLoading(true);
    dispatch(fetchFormattedMap(id));
  }

  const ecosystems_list = maps && maps.map((m, index) => (
  <div key={index} onClick={() => getMapData(m.id)}>
    <MapOverview key={m.id} map={m} onMapSelect={selectMap} selectedId={selectedId} color={mainColor}/>
  </div>))

  return (
    <>
      <div>
        {isOpen && <div className={styles.grayBackground}></div>}
        <div className={styles.inline}> 
          <div className={styles.wrapper} onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? <span>Choose more maps</span> : <span>X</span>}
          </div>
          {isOpen && <div className={styles.ecosystemsList}>{ecosystems_list}</div>}
        </div>
        { selectedMap && 
          <a href={`/maps/${selectedMap.slug}/add_company`} className={styles.add_company_button}>
            <div>
              <img className={styles.bag} src={bag} alt='bag'/>Add or update your company
            </div>
          </a>
        }
        <div className="flex justify-center mt-8" ref={containerRef}>
          { selectedMap && 
            <div className="space-y-4">
              <MapSearch initialQuery={initialQuery} 
                setInitialQuery={setInitialQuery} 
                searchPerformed={searchPerformed} 
                setSearchPerformed={setSearchPerformed} 
                setIsSearchSelect={setIsSearchSelect} 
                setSelectedCompanyNodes={setSelectedCompanyNodes} 
                setOpenedLevel={setOpenedLevel} 
                setCurrentFlow={setCurrentFlow} 
                map={selectedMap} 
                isOpen={isOpen}
                setIsSelectedBySearch={setIsSelectedBySearch}
                setIsInputFilled={setIsInputFilled}
                onResultClick={r => {
                  setSelectedCompanyId(r.id);
                  setSelectedCategories(r.categories);
                  setSelectedCompany(r);
                }
              }/>
              {data && data.nodes && !isLoading ? <ForceGraphMap setInitialQuery={setInitialQuery} 
                                                                 setSearchPerformed={setSearchPerformed} 
                                                                 selectedCompany={selectedCompany} 
                                                                 selectedCompanyId={selectedCompanyId} 
                                                                 isSearchSelect={isSearchSelect}
                                                                 setIsSearchSelect={setIsSearchSelect}
                                                                 selectedCategories={selectedCategories} 
                                                                 selectedCompanyNodes={selectedCompanyNodes} 
                                                                 setSelectedCompanyNodes={setSelectedCompanyNodes} 
                                                                 openedLevel={openedLevel} 
                                                                 setOpenedLevel={setOpenedLevel} 
                                                                 currentFlow={currentFlow} 
                                                                 setCurrentFlow={setCurrentFlow} 
                                                                 data={data} 
                                                                 selectedMap={selectedMap}
                                                                 isSelectedBySearch={isSelectedBySearch} 
                                                                 setIsSelectedBySearch={setIsSelectedBySearch}
                                                                 isInputFilled={isInputFilled}
                                                    /> : 
                <div className={styles.loading}>Loading...</div>}
            </div>
          }
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  map: state.ecosystems.formattedMap,
  success: state.ecosystems.success,
});

export default connect(mapStateToProps)(Ecosystem);
