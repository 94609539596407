import React, { useRef} from "react"
import { classNames } from '../util/misc';
import { ForceGraph2D } from 'react-force-graph';


const RadialGraph = ({data, onNodeClick}) => {

  const fgRef = useRef();

  const loadNodesAndLinks = (data, nodes, links) => {
    nodes.push({
      val: data.val,
      id: data.id,
      name: data.name,
      type: data.type,
    })
    if (data.children) {
      data.children.forEach(child => {
        links.push({
          source: data.id,
          target: child.id
        })
        loadNodesAndLinks(child, nodes, links)
      });
    }
  }

  const nodeColor = node => {
    return node.type === 'COMPANY' ? '#3b82f6' : '#000';
  }

  const nodes = [];
  const links = [];

  loadNodesAndLinks(data, nodes, links)

  const transformedData = { nodes, links }
  
  return (
    <ForceGraph2D
      ref={fgRef}
      graphData={transformedData}
      linkDirectionalArrowLength={10}
      height={900}
      nodeColor={nodeColor}
      cooldownTicks={100}
      onNodeClick={onNodeClick}
      onEngineStop={() => fgRef.current.zoomToFit(400)}
    />
  );
}


export default RadialGraph