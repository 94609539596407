import {
  FETCH_COMPANY_BEGIN,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  SHOW_COMPANY_DRAWER,
  HIDE_COMPANY_DRAWER
} from '../actions/companyActions';

const initialState = {
  company: null,
  loading: false,
  error: null,
  success: null,
  drawerOpen: false,
};

export default function companyReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload.company,
      };

    case FETCH_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SHOW_COMPANY_DRAWER:
      return {
        ...state,
        drawerOpen: true,
      };
    
    case HIDE_COMPANY_DRAWER:
      return {
        ...state,
        drawerOpen: false,
      };

    default:
      return state;
  }
}