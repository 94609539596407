import './ReactForceGraph.css';
import { ForceGraph2D, ForceGraph3D } from 'react-force-graph';
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import Collapsible from 'react-collapsible';
import MapToolbar from './MapToolbar';

import Arrow from '../images/arrow.svg';
import undo from '../images/undo.svg';
import website from '../images/webSite.svg';
import linkedin from '../images/linkedin.svg';

export default function ReactForceGraph({ isInputFilled, setIsSearchSelect, setIsSelectedBySearch, isSelectedBySearch, setInitialQuery, setSearchPerformed, selectedCompany, selectedCompanyId, isSearchSelect, selectedCategories, data, selectedMap, setCurrentFlow, currentFlow, openedLevel, setOpenedLevel, setSelectedCompanyNodes, selectedCompanyNodes }) {
  let links_array = []

  for(let i = 0; i < data.nodes.length; ++i) {
    if(data.nodes[i].child_links !== undefined) {
      for(let j = 0; j < data.nodes[i].child_links.length; ++j) {
        links_array.push(data.nodes[i].child_links[j]);
      }
    }
  }

  const newSelectedCategories = selectedCategories && selectedCategories.map((s) => 'cat_' + s[s.length - 1]?.id);

  const BASE_URL = ''
  const fgRef = useRef(null);
  const rootNodeSize = 2.5;
  const rootCircleWithNumber = 1.8;
  const defaultColors = [
    '#2E90FA',
    '#039855',
    '#EC4A0A',
    '#CC6ECA',
    '#9A69D7',
    '#6970D7',
    '#618041',
    '#CF8557',
    '#A20025',
    '#354A5F',
    '#e39805',
    '#107895',
    '#1286A8',
    '#1395BA',
    '#43ABC8'
  ];
  const primaryColor = '#EF4444';
  const rootId = 0;
  const defaultCavnwasWidth = window.innerWidth - 60;
  const defaultZoom = 50;
  const mapZoom = useRef(defaultZoom);
  const [selectedCompanyParentNodes, setSelectedCompanyParentNodes] = useState(
    []
  );
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [initialCenter, setInitialCenter] = useState(true);
  const [isMapOpened, setIsMapOpened] = useState(false);
  const [levelColors, setLevelColors] = useState([]);
  const [levelsWithCompanies, setLevelsWithCompanies] = useState([]);
  const [openedLevelInfo, setOpenedLevelInfo] = useState(null);
  const [openedLevelCompanies, setOpenedLevelCompanies] = useState([]);
  const [unVisibleNodes, setUnVisibleNodes] = useState([]);
  const [unVisibleLinks, setUnVisibleLinks] = useState([]);
  const [selectedNodeColor, setSelectedNodeColor] = useState('transparent');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(defaultCavnwasWidth);
  const [menuWidth, setMenuWidth] = useState(0);

  const mapView = '2D';

  useEffect(() => {
    fgRef.current.d3Force('charge').strength(-3);
    fgRef.current.d3Force('link').distance(7);
    fgRef.current.d3Force('charge').distanceMax(30);
  }, []);

  useEffect(() => {
    let levedIds = [];

    const levelArr = data.nodes.filter((item) => item.type === 'rootLevel');
    levelArr.forEach((item) => levedIds.push(item.id));
    
    levedIds.forEach((item) => {
      if (!isMapOpened) {
        closeNode(item);
      }

      if (isMapOpened) {
        openNode(item);
      }
    });
  }, [data])

  // find all parent nodes of selected company
  useEffect(() => {
    if (selectedCompanyNodes?.length) {
      handleSelectCompanies(selectedCompanyNodes);
    }
  }, [selectedCompanyNodes]);

  const getSelectedCompanyBreadCrumbs = (id) => {
    if (id) {
      const LinkNodes = getSelectedCompanies([id]);

      const filteredLinkNodes = LinkNodes.filter(function (item, pos) {
        return LinkNodes.indexOf(item) === pos;
      });

      let categories = [];
      if (filteredLinkNodes && filteredLinkNodes?.length) {
        filteredLinkNodes.forEach((item) => {
          const node = data.nodes.find((i) => i.id === item);
          const category = node?.name;
          if (category) {
            categories.push(category);
          }
        });
      }

      if (categories.length) {
        const revesedCategories = categories.reverse();
        const breadCrumbs = revesedCategories.join(' > ');
        return breadCrumbs;
      } else return '';
    } else return '';
  };

  const getLevelsWithCompanies = () => {
    const companies = data.nodes.filter((item) => item.type === 'COMPANY');
    let levels = [];

    companies.forEach((c) => {

      for(let i = 0; i < links_array.length; ++i) {
        let companyLink;

        if(links_array[i].target === c.id) {
          companyLink = links_array[i];
        } else {
          continue;
        }

        const isSource = links_array.find((i) => i === companyLink?.source);
        if (!isSource) {
          levels.push(companyLink?.source);
        }
      }
    });
    const filteredLevels = levels.filter(function (item, pos) {
      return levels.indexOf(item) === pos;
    });
    return filteredLevels;
  };

  useEffect(() => {
    setLevelsWithCompanies(getLevelsWithCompanies());
  }, [data]);

  //find opened level companies
  useEffect(() => {
    if (openedLevel) {
      let openedLevelCompanies = [];
      const openedLevelChildlinks = data.nodes.find(
        (item) => item.id === openedLevel || item.id === openedLevel.id
      )?.child_links;

      //close previous state

      if (openedLevelChildlinks?.length) {
        openedLevelChildlinks.forEach((item) => {
          
          const company = data.nodes.find(
            (i) => item.target === i.id || item.target.id === i.id
          );
          if (company && company.type === 'level') {
            return;
          }
          if (
            (selectedCompanies.find((i) => company.id === i) ||
            selectedCompanyNodes.find((i) => company.id === i)) &&
            isSearchSelect
          ) {
            company.open = true;
          } else company.open = false;
          if (company) {
            openedLevelCompanies.push(company);
          }
        });
      }
      if (openedLevelCompanies?.length) {
        const sortedCompanies = openedLevelCompanies.sort((x) =>
          x.open ? -1 : 1
        );
        setOpenedLevelCompanies(sortedCompanies);
      }

      setOpenedLevelInfo(
        data.nodes.find(
          (item) => item.id === openedLevel || item.id === openedLevel.id
        )
      );
    }
  }, [openedLevel]);

  //handle node opening depended of search flow
  useEffect(() => {
    if (selectedCompanies?.length) {
      selectedCompanies.forEach((item) => {
        if (
          item !== openedLevel &&
          !levelsWithCompanies.find((i) => i === item)
        ) {
          if (currentFlow === 'openLevel') {
            setOpenedLevel(null);
            return;
          } else openNode(item);
        }
        if (
          currentFlow === 'findCompany' &&
          levelsWithCompanies.find((i) => i === item)
        ) {
          openNode(item);
        }
      });
    }
  }, [selectedCompanies, openedLevel]);

  const [currentComponentId, setCurrentComponentId] = useState(null);

  useEffect(() => {
    if(openedLevelCompanies) {
      for(let i = 0; i < openedLevelCompanies.length; ++i) {
        if(openedLevelCompanies[i].id === currentComponentId) {
          openedLevelCompanies[i].open = true;
          const sortedCompanies = openedLevelCompanies.sort((x) =>
            x.id === openedLevelCompanies[i].id ? -1 : 1
          );
          setOpenedLevelCompanies(sortedCompanies);
        }
      }
    }
  }, [openedLevelCompanies])

  const getNotSelectedNodes = (nodeId) => {
    setCurrentComponentId(nodeId);
    if(selectedCompanies?.length) {
        selectedCompanies.forEach((item) => {
        closeNode(item);
      });
    }
    setSelectedCompanyNodes([]);

    let unVisibleNodes = [];
    let unVisibleLinks = [];

    if (nodeId) {
      let openedNodeLink = [];
      for(let i = 0; i < links_array.length; ++i) {
        if(links_array[i].target === nodeId || links_array[i].target.id === nodeId) {
          openedNodeLink.push(links_array[i]);
        }
      }

      let openedNode = [];
      for(let i = 0; i < data.nodes.length; ++i) {
        for(let j = 0; j < openedNodeLink.length; ++j) {
          if(openedNodeLink[j].source === data.nodes[i].id ||
            openedNodeLink[j].source.id === data.nodes[i].id) {
              openedNode.push(data.nodes[i]);
            }
        }
      }

      let childNodes = [];
      let child_links = [];

      for(let i = 0; i < openedNode.length; ++i) {
        if(openedNode[i].child_links.length) {
          for(let j = 0; j < openedNode[i].child_links.length; ++j) {
            if(openedNode[i].child_links[j].target !== nodeId && openedNode[i].child_links[j].target?.id !== nodeId) {
              if(typeof openedNode[i].child_links[j].target === 'object') {
                child_links.push(openedNode[i].child_links[j].target.id);
              }
              if(typeof openedNode[i].child_links[j].target !== 'object') {
                child_links.push(openedNode[i].child_links[j].target)
              }
            }
            if(openedNode[i].child_links[j].target !== nodeId && openedNode[i].child_links[j].target?.id !== nodeId) {
              if(typeof openedNode[i].child_links[j].target === 'object') {
                childNodes.push(openedNode[i].child_links[j].target.id);
              }
              if(typeof openedNode[i].child_links[j].target !== 'object') {
                childNodes.push(openedNode[i].child_links[j].target)
              }
            }
          }
        }
      }

      unVisibleNodes.push(...childNodes);
      unVisibleLinks.push(...child_links);
      setUnVisibleNodes((prev) => [...prev, ...unVisibleNodes]);
      setUnVisibleLinks((prev) => [...prev, ...unVisibleLinks]);
    }
  };

  //handle menu opening by selected companies
  useEffect(() => {
    selectedCompanyParentNodes.forEach((item) => {
      closeNode(item);
    });
    setUnVisibleNodes([]);
    setUnVisibleLinks([]);
    selectedCompanyNodes.forEach((item) => {
      getNotSelectedNodes(item);
    });

    if (selectedCompanyNodes?.length) {
      const getParetnNode = (id) => {
        const parentLink =
          links_array.find((item) => item.target === id) ||
          links_array.find((item) => item.target.id === id);
          
        if (parentLink?.source?.id) {
          return parentLink?.source?.id;
        }
        if (parentLink?.source) {
          return parentLink?.source;
        }
        // const parentId = parentLink?.source;
        // return parentId;
      };
      const getNodeColor = (id) => {
        const nodeColor = data.nodes.find((item) => item.id === id)?.color;
        return nodeColor;
      };
      setSelectedNodeColor(getNodeColor(selectedCompanyNodes[0]));

      setOpenedLevel(getParetnNode(selectedCompanyNodes[0]));
      setIsMenuOpen(true);
      setCanvasWidth(defaultCavnwasWidth - 400);
      setMenuWidth(500);
    }
  }, [selectedCompanyNodes]);

  //seting colors to nodes
  useEffect(() => {
    let levedIds = [];
    const levelArr = data.nodes.filter((item) => item.type === 'rootLevel');
    levelArr.forEach((item) => levedIds.push(item.id));
    let levelColors = [];

    levedIds.forEach((item, index) => {
      levelColors.push({ id: item, color: defaultColors[index] });
    });

    // let nodesWithColor = data.nodes;

    function findNode(id) {
      return data.nodes.find((item) => item.id === id);
    }

    function setNodeColor(id, color) {
      const node = findNode(id);
      node.color = color;
      node.child_links.forEach((item) => setNodeColor(item.target, color));
    }

    levelColors.forEach((item) => setNodeColor(item.id, item.color));
    setLevelColors(levelColors);
    // setData((prevData) => ({ ...prevData, nodes: nodesWithColor }));
  }, [data.nodes]);

  // link parent/children
  const nodesById = useMemo(() => {
    const nodesById = Object.fromEntries(
      data.nodes.map((node) => [node.id, node])
    );

    // link parent/children
    data.nodes.forEach((node) => {
      node.collapsed = node.id !== rootId;
      // if(node.id ===21 || node.id ===20) {
      //   node.collapsed = true
      // }
      node.child_links = [];
    });
    links_array.forEach((link) => {
      if (nodesById[link.source]) {
        nodesById[link.source].child_links.push(link);
      }
    });
    return nodesById;
  }, [data]);

  //get data for opened nodes and modify links object
  const getPrunedTree = useCallback(() => {
    const visibleNodes = [];
    const visibleLinks = [];
    (function traverseTree(node = nodesById[rootId]) {
      visibleNodes.push(node);
      if (node.collapsed) return;
      visibleLinks.push(...node.child_links);
      node.child_links
        .map((link) =>
          typeof link.target === 'object' ? link.target : nodesById[link.target]
        ) // get child node
        .forEach(traverseTree);
    })();

    let filteredNodes = [];
    let filteredLinks = [];
    visibleNodes.forEach((item) => {
      const isUnvisibleNode = unVisibleNodes.find((i) => i === item.id);
      if (!isUnvisibleNode) {
        filteredNodes.push(item);
      }
    });

    visibleLinks.forEach((item) => {
      const isUnvisibleLink = unVisibleLinks.find(
        (i) => item.target === i || item.target.id === i
      );
      if (!isUnvisibleLink) {
        filteredLinks.push(item);
      }
    });

    return { nodes: filteredNodes, links: filteredLinks };
  }, [nodesById, unVisibleNodes, unVisibleLinks]);

  const [prunedTree, setPrunedTree] = useState(getPrunedTree());
  const [currentNode, setCurrentNode] = useState(null);

  const handleNodeClick = useCallback(
    (node) => {
      if(node.type !== 'COMPANY') {
        if(isSelectedBySearch) {
          for(let i = 0; i < isSelectedBySearch.length; ++i) {
            for(let j = 1; j < isSelectedBySearch[i].length; ++j) {
              closeNode('cat_' + isSelectedBySearch[i][j].id);
              closeMenu();
            }
          }
          setIsSelectedBySearch(null)
        }

        setOpenedLevelCompanies(null);
        setIsSearchSelect(false);
        setCurrentNode(node);
        setOpenedLevel(null);
        setCurrentFlow('openLevel');

        const isLevelWithCompanies = levelsWithCompanies.find(
          (item) => item === node.id
        );
        const isSelectedCompany = selectedCompanies.find(
          (item) => item === node.id
        );
        if (!isSelectedCompany) {
          setSelectedCompanies([]);
          selectedCompanyParentNodes.forEach((item) => closeNode(item));
  
          setSelectedCompanyParentNodes([]);
        }
        //close previous opened items in menu
        if (openedLevelCompanies?.length) {
          const companies = openedLevelCompanies.forEach(
            (item) => (item.open = false)
          );
          setOpenedLevelCompanies(companies);
        }
        // handle click on level with companies
        if (isLevelWithCompanies) {
          if(openedLevel !== node.id) {
            setSelectedNodeColor(node.color);
            setOpenedLevel(node.id);
            setIsMenuOpen(true);
            setCanvasWidth(defaultCavnwasWidth - 400);
            setMenuWidth(500);
            fgRef.current.zoom(15, 1000);
            fgRef.current.centerAt(node.x, node.y, 2000);
            handleSelectCompanies([node.id]);
            setCurrentFlow('openLevel');
          }
  
          if(openedLevel === node.id) {
            closeMenu()
          }
        } else {
          // handle click on level without companies
          if (isMenuOpen && currentFlow === 'openLevel') {
            closeMenu();
          }
          if(!node.collapsed) {
            fgRef.current.centerAt(0, 0, 1000);
          }
          if(node.collapsed) {
            setTimeout(() => {
              fgRef.current.centerAt(node.x, node.y, 1000);
            }, 700)
          }
  
          fgRef.current.zoom(25, 1000);
          node.collapsed = !node.collapsed; // toggle collapse state
          setPrunedTree(getPrunedTree());
        }
      }
    },
    [
      fgRef,
      getPrunedTree,
      levelsWithCompanies,
      isMenuOpen,
      currentFlow,
      selectedCompanies,
      selectedCompanyParentNodes,
      isSearchSelect
    ]
  );

  const openNode = (nodeId) => {
    data.nodes.forEach((item) => {
      if (item.id === nodeId) {
        item.collapsed = false; // toggle collapse state
      }
    });

    setPrunedTree(getPrunedTree());
  };

  const closeNode = (nodeId) => {
    data.nodes.forEach((item) => {
      if (item.id === nodeId) {
        item.collapsed = true; // toggle collapse state
      }
    });

    setPrunedTree(getPrunedTree());
  };

  const toggleMap = (data) => {
    let levedIds = [];
    const levelArr = data.nodes.filter((item) => item.type === 'rootLevel');
    levelArr.forEach((item) => levedIds.push(item.id));
    if (isMapOpened && isMenuOpen) {
      closeMenu();
    }
    levedIds.forEach((item) => {
      if (isMapOpened) {
        closeNode(item);
        setSelectedCompanies([]);
        setSelectedCompanyNodes([]);
      }
      if (!isMapOpened) {
        openNode(item);
      }
    });

    setIsMapOpened(!isMapOpened);
  };

  const handleLinkWidth = (link) => {
    if (selectedCompanies?.length) {
      if (selectedCompanies.some((item) => item === link.target.id)) {
        return 2;
      } else return 2;
    } else return 2;
  };

  const handleLinkDash = (link) => {
    if (selectedCompanies?.length) {
      if (selectedCompanies.some((item) => item === link.target.id)) {
        link.dashed = true;

        return [1, 0.5];
      } else {
        link.dashed = false;
        return [];
      }
    } else {
      link.dashed = false;
      return [];
    }
  };

  const handleLinkColor = (link) => {
    const getColor = (id) => {
      const nodeItem = data.nodes.find((item) => item.id === id);
      if (nodeItem && nodeItem?.color) return nodeItem.color;
      else return primaryColor;
    };
    const isRootLevel = levelColors.find(
      (item) => item.id === link.target || item.id === link.target.id
    );

    const isCompanyNode = data.nodes.find(
      (item) =>
        (item.id === link.target || item.id === link.target.id) &&
        item.type === 'COMPANY'
    );
    const isSelectedNode = selectedCompanies.find(
      (item) => item === link.target.id || item === link.target
    );
    const isOpenedLevel =
      link.target.id === openedLevel || link.target === openedLevel;

    if (isRootLevel) {
      const isSelectedLevel = selectedCompanies.find(
        (item) => item === link.target || item === link.target.id
      );
      if (isSelectedLevel || (!openedLevel && !selectedCompanies?.length)) {
        return primaryColor;
      } else if (
        (openedLevel || selectedCompanies?.length) &&
        !isSelectedLevel
      ) {
        return LightenDarkenColor(primaryColor, 120);
      } else return LightenDarkenColor(primaryColor, 60);
    } else if (isCompanyNode) {
      if (link.source.id) {
        const color = getColor(link.source.id);
        if (!isSelectedNode && !isOpenedLevel) {
          if (openedLevel || selectedCompanies?.length) {
            return LightenDarkenColor(color, 120);
          } else return LightenDarkenColor(color, 60);
        } else return color;
      } else return primaryColor;
    } else if (link.target.id) {
      const color = getColor(link.target.id);

      if (!isSelectedNode && !isOpenedLevel) {
        if (openedLevel || selectedCompanies?.length) {
          return LightenDarkenColor(color, 120);
        } else return LightenDarkenColor(color, 60);
      } else return color;
    } else return primaryColor;
  };

  const getSelectedCompanies = (companiesIds) => {
    const result = [];
    const getLinkIds = (id) => {
      const findLink = (id) =>
        links_array.find((item) => item.target === id || item.target.id === id);
      result.push(id);
      let currentLink = findLink(id);
      while (currentLink !== undefined) {
        const id =
          typeof currentLink?.source !== 'object'
            ? currentLink?.source
            : currentLink?.source?.id;
        if (
          typeof currentLink?.source === 'object' &&
          currentLink?.source?.id
        ) {
          result.push(currentLink.source.id);
        }
        if (typeof currentLink?.source !== 'object' && currentLink?.source) {
          result.push(currentLink.source);
        }
        currentLink = findLink(id);

        if (
          typeof currentLink?.target === 'object' &&
          currentLink?.target?.id
        ) {
          result.push(currentLink.target.id);
        }
        if (typeof currentLink?.target !== 'object' && currentLink?.target) {
          result.push(currentLink.target);
        }
      }
    };

    companiesIds.forEach((item) => {
      getLinkIds(item);
    });
    return result;
  };

  const handleSelectCompanies = (companiesArr) => {
    let selectedCompanies;
    if(isSearchSelect) {
      selectedCompanies = getSelectedCompanies(newSelectedCategories);
    }
    if(!isSearchSelect) {
      selectedCompanies = getSelectedCompanies(companiesArr);
    }

    if (selectedCompanies?.length) {
      const filteredIds = selectedCompanies.filter(function (item, pos) {
        return selectedCompanies.indexOf(item) === pos;
      });
      if(selectedCompanyId !== 1) {
        filteredIds.push('comp_' + selectedCompanyId);
      }

      setSelectedCompanies(filteredIds);
    } else return;
  };

  const handleNodeColor = (node) => {};

  function LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col && col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  // const handleLinkCanvas = (link, ctx, globalScale) => {
  //   console.log('link', link)
  // }
  let temp = false;

  const [currentCompanyNode, setCurrentCompanyNode] = useState(null);

  if(currentCompanyNode && isSearchSelect && !isInputFilled) {
    setTimeout(() => {
      fgRef.current.zoom(15, 1000);
      fgRef.current.centerAt(currentCompanyNode.x, currentCompanyNode.y, 1000);
    }, 500)
  }

  const handleNodeCanvas = (node, ctx, globalScale) => {
    if((node?.id === 'comp_' + selectedCompany?.id) && !temp) {
      setCurrentCompanyNode(node);
      temp = true;
    }

    if(node.type === 'root')
    
    mapZoom.current = globalScale;
    function fillCircle(x, y, radius, color) {
      ctx.save();
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.restore();
    }
    function fillCircleWithShadow(
      x,
      y,
      radius,
      color,
      shadowColor,
      strokeStyle,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY
    ) {
      ctx.save();
      ctx.fillStyle = color;
      ctx.shadowColor = shadowColor;
      ctx.strokeStyle = strokeStyle;
      ctx.shadowBlur = shadowBlur;
      ctx.shadowOffsetX = shadowOffsetX;
      ctx.shadowOffsetY = shadowOffsetY;
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.restore();
    }
    function strokeCircle(x, y, radius, color, lineWidth) {
      ctx.save();
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.restore();
    }

    //handle root node
    if (node.type === 'root') {

      ctx.save();
      fillCircle(node.x, node.y, rootNodeSize, 'white');
      strokeCircle(node.x, node.y, rootNodeSize, primaryColor, 0.02);
      strokeCircle(node.x, node.y, rootNodeSize + 0.2, primaryColor, 0.02);
      ctx.restore();
    }
    //handle open level node
    if (node.id === openedLevel) {
      // HANDLE LINE FROM SELECTED CATEGORY TO MENU
      // const menuPosition = fgRef.current.screen2GraphCoords(canvasWidth, 50);
      // const nodePosition = fgRef.current.screen2GraphCoords(node.x, node.y);
      // const x = nodePosition.x;
      // const y = nodePosition.y;
      // const leftPosition = fgRef.current.screen2GraphCoords(
      //   canvasWidth - 40,
      //   node.y
      // );
      // const topPosition = fgRef.current.screen2GraphCoords(leftPosition.x, 50);
      // ctx.save();
      // // node.fx = node.x;
      // // node.fy = node.y;
      // ctx.strokeStyle = node.color;
      // ctx.setLineDash([1, 0.5]);
      // ctx.moveTo(node.x, node.y);
      // // line to top
      // // ctx.lineTo(node.x, topPosition.y + 5);
      // // ctx.arcTo(node.x, topPosition.y, node.x + 5, topPosition.y, 5);
      // // ctx.lineTo(menuPosition.x, menuPosition.y);
      // //line to left
      // // ctx.lineTo(leftPosition.x-2, node.y);
      // // ctx.arcTo(leftPosition.x, node.y, leftPosition.x, node.y - 2, 2);
      // // ctx.lineTo(leftPosition.x, topPosition.y+2);
      // // ctx.arcTo(leftPosition.x, topPosition.y, leftPosition.x + 2, topPosition.y, 2);
      // ctx.lineTo(menuPosition.x, menuPosition.y);
      // ctx.lineWidth = 0.1;
      // ctx.stroke();
      // ctx.restore();
    }
    const isSelectedChaineNode = selectedCompanies.some(
      (item) => item === node.id
    );
    const isOpenedLevel = node.id === openedLevel;

    //handle node color depended of node state
    if (node.type === 'rootLevel') {
      if (selectedCompanies?.length || openedLevel) {
        const levelColor =
          (selectedCompanies?.length && isSelectedChaineNode && node.color) ||
          (isOpenedLevel && node.color)
            ? LightenDarkenColor(node.color, -30)
            : LightenDarkenColor(node.color, 80);
        if (node.child_links?.length !== 0) {

          //node size 1 - 5
          if(node.child_links?.length <= 5) {
            fillCircle(
              node.x,
              node.y,
              1.5,
              levelColor
            );
          }
          if(node.child_links?.length <= 5) {
            strokeCircle(
              node.x,
              node.y,
              1.5 + 0.2,
              levelColor,
              0.02
            );
          }

          //node size 6 - 15
          if(node.child_links?.length > 5 && node.child_links?.length <= 15) {
            fillCircle(
              node.x,
              node.y,
              2,
              levelColor
            );
          }
          if(node.child_links?.length > 5 && node.child_links?.length <= 15) {
            strokeCircle(
              node.x,
              node.y,
              2 + 0.2,
              levelColor,
              0.02
            );
          }

          //node size 16 - 25
          if(node.child_links?.length > 15 && node.child_links?.length <= 25) {
            fillCircle(
              node.x,
              node.y,
              3,
              levelColor
            );
          }
          if(node.child_links?.length > 15 && node.child_links?.length <= 25) {
            strokeCircle(
              node.x,
              node.y,
              3 + 0.2,
              levelColor,
              0.02
            );
          }
        } 
        if(node.child_links?.length > 25) {
          fillCircle(
            node.x,
            node.y,
            4,
            levelColor
          );
        }
      } else if (node.child_links?.length !== 0) {
        const levelColor = node.color ? node.color : primaryColor;

        //node size 1 - 5
        if(node.child_links?.length <= 5) {
          fillCircle(
            node.x,
            node.y,
            1.5,
            levelColor
          );
        }
        if(node.child_links?.length <= 5) {
          strokeCircle(
            node.x,
            node.y,
            1.5 + 0.2,
            levelColor,
            0.02
          );
        }

        //node size 6 - 15
        if(node.child_links?.length > 5 && node.child_links?.length <= 15) {
          fillCircle(
            node.x,
            node.y,
            2,
            levelColor
          );
        }
        if(node.child_links?.length > 5 && node.child_links?.length <= 15) {
          strokeCircle(
            node.x,
            node.y,
            2 + 0.2,
            levelColor,
            0.02
          );
        }

        //node size 16 - 25
        if(node.child_links?.length > 15 && node.child_links?.length <= 25) {
          fillCircle(
            node.x,
            node.y,
            3,
            levelColor
          );
        }
        if(node.child_links?.length > 15 && node.child_links?.length <= 25) {
          strokeCircle(
            node.x,
            node.y,
            3 + 0.2,
            levelColor,
            0.02
          );
        }
      } 
      if(node.child_links?.length > 25) {
        fillCircle(
          node.x,
          node.y,
          4,
          primaryColor
        );
      }
      // strokeCircle(
      //   node.x,
      //   node.y,
      //   node.childLinks?.length / 2 + 0.2,
      //   primaryColor,
      //   0.02
      // );
    }
    if (node.type === 'level') {
      const levelColor = node.color
        ? LightenDarkenColor(node.color, 60)
        : (node.__indexColor === '#10000c' ? '#82007e' : node.__indexColor);
      if (selectedCompanies?.length) {
        const levelColor =
          isSelectedChaineNode
            ? LightenDarkenColor(node.color ? node.color : (node.__indexColor === '#10000c' ? '#82007e' : node.__indexColor), -30)
            : LightenDarkenColor(node.color ? node.color : (node.__indexColor === '#10000c' ? '#82007e' : node.__indexColor), 120);
            if (node.child_links?.length >= 1 && node.child_links?.length < 6) {
              fillCircle(
                node.x,
                node.y,
                1.3,
                levelColor
              );
            } 
            else if (node.child_links?.length >= 6 && node.child_links?.length < 13) {
              fillCircle(
                node.x,
                node.y,
                1.5,
                levelColor
              );
            } else if (node.child_links?.length >= 13 && node.child_links?.length < 25) {
              fillCircle(
                node.x,
                node.y,
                1.7,
                levelColor
              );
            } else
              fillCircle(
                node.x,
                node.y,
                1.9,
                levelColor
              );
      } 
      // companies doesn't selected
      else if (node.child_links?.length >= 1 && node.child_links?.length < 6) {
        fillCircle(
          node.x,
          node.y,
          1.3,
          levelColor
        );
      } 
      else if (node.child_links?.length >= 6 && node.child_links?.length < 13) {
        fillCircle(
          node.x,
          node.y,
          1.5,
          levelColor
        );
      } else if (node.child_links?.length >= 13 && node.child_links?.length < 25) {
        fillCircle(
          node.x,
          node.y,
          1.7,
          levelColor
        );
      } else
        fillCircle(
          node.x,
          node.y,
          1.9,
          levelColor
        );
    }
    if (node.type === 'COMPANY') {
      fillCircle(node.x, node.y, 1, ctx.fillStyle = "rgba(0, 0, 0, 0.10)");
    }

    function fittingString(c, str, maxWidth) {
      var width = c.measureText(str).width;
      var ellipsis = '';
      var ellipsisWidth = c.measureText(ellipsis).width;
      if (width <= maxWidth || width <= ellipsisWidth) {
        return str;
      } else {
        var len = str?.length;
        while (width >= maxWidth - ellipsisWidth && len-- > 0) {
          str = str.substring(0, len);
          width = c.measureText(str).width;
        }
        return str + ellipsis;
      }
    }

    // create node label
    const label = node.name;
    const fontSize = 0.5;
    ctx.font = `${fontSize}px Sans-Serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    if (node.child_links?.length) {
      
      ctx.fillStyle = (selectedCompanies?.length && isSelectedChaineNode && node.color) ||
      (isOpenedLevel && node.color)
        ? 'white'
        : (node.color ? node.color : 'white');

      if(!selectedCompanies[0]) {
        ctx.fillStyle = 'white';
      }
      

      // if (selectedCompanies?.length || openedLevel) {
      //   ctx.fillStyle =
      //     (selectedCompanies?.length && isSelectedChaineNode && node.color) ||
      //     (isOpenedLevel && node.color)
      //       ? 'white'
      //       : '#EF4444';
      // }

      //nodes size 1 - 6
      if (node.type !== 'level' && node.child_links?.length >= 1 && node.child_links?.length < 6) {
        ctx.fillText(
          fittingString(ctx, label, 2.6),
          node.x,
          node.y
        );
      }
      if (node.type === 'level' && node.child_links?.length >= 1 && node.child_links?.length < 6) {
        ctx.fillText(
          fittingString(ctx, label, 2.3),
          node.x,
          node.y
        );
      }

      //nodes size 6 - 15
      if (node.type !== 'level' && node.child_links?.length > 5 && node.child_links?.length < 16) {
        ctx.fillText(
          fittingString(ctx, label, 3.8),
          node.x,
          node.y
        );
      }
      if (node.type === 'level' && node.child_links?.length > 5 && node.child_links?.length < 16) {
        ctx.fillText(
          fittingString(ctx, label, 2.6),
          node.x,
          node.y
        );
      }

      //nodes size 16 - 25
      if (node.type !== 'level' && node.child_links?.length > 15 && node.child_links?.length < 26) {
        ctx.fillText(
          fittingString(ctx, label, 4.6),
          node.x,
          node.y
        );
      }
      if (node.type === 'level' && node.child_links?.length > 15 && node.child_links?.length < 26) {
        ctx.fillText(
          fittingString(ctx, label, 3.3),
          node.x,
          node.y
        );
      }

      if (node.type !== 'level' && node.child_links?.length > 25) {
        ctx.fillText(
          fittingString(ctx, label, 5.0),
          node.x,
          node.y
        );
      }
      if (node.type === 'level' && node.child_links?.length > 25) {
        ctx.fillText(
          fittingString(ctx, label, 3.5),
          node.x,
          node.y
        );
      }
    } else if (globalScale >= 9) {
        ctx.fillStyle = '#000'; //node text color;
        ctx.fillText(fittingString(ctx, label, 3.5), 
        node.x, 
        node.y + 1.5
      );
      // const lines = label.split(',');
      // let x = node.x;
      // let y = node.y - lineHeight;
      // for (let i = 0; i < lines?.length; ++i) {
      //   ctx.fillText(lines[i], x + 3, y + 1);
      //   y += lineHeight;
      // }
    }
    //create node image
    const img = new Image();
    const logo = new Image();

    img.src = node.type === 'root' ? BASE_URL + selectedMap.root_image_url : null;
    logo.src = node.type === 'COMPANY' ? BASE_URL + node.logo : null;

    node.img = img;
    if (node.type === 'root') {
      const size = 3;

      ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
    }
    if (node.type === 'COMPANY') {
      const size = 1;
      ctx.drawImage(logo, node.x - size / 2, node.y - size / 2, size, size);
    }

    // create circles with number
    if (node.child_links?.length) {
      const shadowColor =
        (selectedCompanies?.length && isSelectedChaineNode) || isOpenedLevel
          ? 'rgba((16, 24, 40, 0.06))'
          : 'rgba((16, 24, 40, 0.01))';
      const textColor =
        (selectedCompanies?.length && isSelectedChaineNode) ||
        isOpenedLevel ||
        (!selectedCompanies?.length && !isOpenedLevel)
          ? '#000'
          : 'grey';

      if(node.type === 'root') {
        fillCircleWithShadow(
          node.x + rootCircleWithNumber,
          node.y - rootCircleWithNumber,
          0.5,
          'white',
          textColor,
          shadowColor,
          5,
          0,
          0
        );
      }

      if(node.type === 'rootLevel') {

        //nodes size 1 - 5
        if(node.child_links?.length > 0 && node.child_links?.length < 6) {
          fillCircleWithShadow(
            node.x + 1.1,
            node.y - 1.1,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }

        //nodes size 6 - 15
        if(node.child_links?.length > 5 && node.child_links?.length < 16) {
          fillCircleWithShadow(
            node.x + 1.4,
            node.y - 1.4,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }

        //nodes size 16 - 25
        if(node.child_links?.length > 15 && node.child_links?.length < 26) {
          fillCircleWithShadow(
            node.x + 2.1,
            node.y - 2.1,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }

        if(node.child_links?.length > 25) {
          fillCircleWithShadow(
            node.x + 2.5,
            node.y - 2.5,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }
      }

      if(node.type === 'level') {

        //nodes size 1 - 5
        if(node.child_links?.length > 0 && node.child_links?.length < 6) {
          fillCircleWithShadow(
            node.x + 0.9,
            node.y - 0.9,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }

        //nodes size 6 - 15
        if(node.child_links?.length > 5 && node.child_links?.length < 16) {
          fillCircleWithShadow(
            node.x + 1,
            node.y - 1,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }

        //nodes size 16 - 25
        if(node.child_links?.length > 15 && node.child_links?.length < 26) {
          fillCircleWithShadow(
            node.x + 1.2,
            node.y - 1.2,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }

        if(node.child_links?.length > 25) {
          fillCircleWithShadow(
            node.x + 1.3,
            node.y - 1.3,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }
      }

      ctx.beginPath();
      const label = node.number_of_companies;
      const fontSize = 0.5;
      ctx.font = `${fontSize}px Sans-Serif`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = textColor; //node text color;

      if(node.type === 'root') {
        ctx.fillText(
          label,
          node.x + rootCircleWithNumber,
          node.y - rootCircleWithNumber
        );
      }

      if(node.type === 'rootLevel') {
        //nodes size 1 - 6
        if(node.child_links?.length > 0 && node.child_links?.length < 6) {
          ctx.fillText(
            label,
            node.x + 1.1,
            node.y - 1.1
          );
        }

        //nodes size 6 - 15
        if(node.child_links?.length > 5 && node.child_links?.length < 16) {
          ctx.fillText(
            label,
            node.x + 1.4,
            node.y - 1.4
          );
        }

        //nodes size 16 - 25
        if(node.child_links?.length > 15 && node.child_links?.length < 26) {
          ctx.fillText(
            label,
            node.x + 2.1,
            node.y - 2.1
          );
        }

        if(node.child_links?.length > 25) {
          fillCircleWithShadow(
            node.x + 2.5,
            node.y - 2.5,
            0.5,
            'white',
            textColor,
            shadowColor,
            5,
            0,
            0
          );
        }
      }

      if(node.type === 'level') {

        //nodes size 1 - 5
        if(node.child_links?.length > 0 && node.child_links?.length < 6) {
          ctx.fillText(
            label,
            node.x + 0.9,
            node.y - 0.9
          );
        }

        //nodes size 6 - 15
        if(node.child_links?.length > 5 && node.child_links?.length < 16) {
          ctx.fillText(
            label,
            node.x + 1,
            node.y - 1
          );
        }

        //nodes size 16 - 25
        if(node.child_links?.length > 15 && node.child_links?.length < 26) {
          ctx.fillText(
            label,
            node.x + 1.2,
            node.y - 1.2
          );
        }

        if(node.child_links?.length > 25) {
          ctx.fillText(
            label,
            node.x + 1.3,
            node.y - 1.3
          );
        }
      }
    }
  };

  const handleEngineStop = () => {
    if (initialCenter) {
      fgRef.current.zoomToFit(1000, defaultZoom);
    }
    if (selectedCompanies?.length && !isSearchSelect) {
      // fgRef.current.zoomToFit(1000, defaultZoom);
    }
    if (isMapOpened && !isSearchSelect) {
      setInitialCenter(true);
      fgRef.current.zoomToFit(1000, defaultZoom);
    } else setInitialCenter(false);
  };

  const onTriggerClose = (id) => {
    // console.log('id', id);
  };

  const onTriggerOpen = (id) => {
    // console.log('id', id);
  };
  const handleZoomIn = () => {
    mapZoom.current = mapZoom.current + 5;
    fgRef.current.zoom(mapZoom.current, 1000);
  };

  const handleZoomOut = () => {
    mapZoom.current = mapZoom.current - 5;
    fgRef.current.zoom(mapZoom.current, 1000);
  };

  const handleCenter = () => {
    fgRef.current.zoomToFit(1000, defaultZoom);
  };

  const handleMapToolbarClick = () => {
    if(isSelectedBySearch) {
      for(let i = 0; i < isSelectedBySearch.length; ++i) {
        for(let j = 1; j < isSelectedBySearch[i].length; ++j) {
          closeNode('cat_' + isSelectedBySearch[i][j].id);
          closeMenu();
        }
      }
      setIsSelectedBySearch(null)
    }
    toggleMap(data);
  };
  const closeMenu = () => {
    setSelectedCompanyNodes([]);
    setIsMenuOpen(false);
    setCanvasWidth(defaultCavnwasWidth);
    setMenuWidth(0);
    fgRef.current.zoomToFit(1000, defaultZoom);
    setOpenedLevel(null);
    setSelectedCompanies([])
    setIsSearchSelect(false);
  };

  const [displayWidth, setDisplayWidth] = useState(window.innerWidth);
  const [displayHeight, setDisplayHeight] = useState(window.innerHeight);

  window.addEventListener('resize', () => {
    setTimeout(() => {
      setDisplayWidth(window.innerWidth);
    }, 500)

    setTimeout(() => {
      setDisplayHeight(window.innerHeight);
    }, 500)
  });
  
  const AccordionItemTrigger = ({ flag, logo, text }) => (
    <div className="triggerWrap">
      <div className="triggerContent">
        <img className="triggerflag" src={flag} alt="" />
        <img className="triggerLogo" src={logo} alt="" />
        <div className="triggerText">{text}</div>
      </div>
      <div>
        <img src={Arrow} alt="arrow" />
      </div>
    </div>
  );

  const hideSearchModal = () => {
    setInitialQuery('');
    setSearchPerformed(false);
  }

  return (
    <div onClick={hideSearchModal} className="mapContainer">
      <div className="wrap">
        <div className="canvasWrap" style={{ width: displayWidth }}>
          <div id="capture">
            {mapView === '2D' ? (
              <ForceGraph2D
                // onNodeDragEnd={node => {
                //   node.fx = node.x; 
                //   node.fy = node.y; 
                //   node.fz = node.z; 
                // }}
                width={displayWidth - 20}
                height={displayHeight - 130}
                ref={fgRef}
                graphData={prunedTree}
                onZoomEnd={(args) => {
                  // console.log("onZoomEnd args", args);
                  // toggleMap(data);
                }}
                onNodeClick={handleNodeClick}
                cooldownTicks={50}
                nodeRelSize={1.7}
                nodeVisibility={true}
                linkVisibility={true}
                nodeColor={handleNodeColor}
                onEngineStop={handleEngineStop}
                linkColor={handleLinkColor}
                linkWidth={handleLinkWidth}
                linkLineDash={handleLinkDash}
                nodeCanvasObjectMode={() => 'replace'}
                nodeCanvasObject={handleNodeCanvas}
                enableZoomInteraction={false}
                // linkCanvasObject={handleLinkCanvas}
                // linkCanvasObjectMode={() => 'after'}
                // autoPauseRedraw={true}
              />
            ) : (
              <ForceGraph3D
                width={canvasWidth}
                height={600}
                ref={fgRef}
                graphData={prunedTree}
                onNodeClick={handleNodeClick}
                cooldownTicks={50}
                nodeRelSize={1}
                nodeVisibility={true}
                linkVisibility={true}
                nodeColor={handleNodeColor}
                onEngineStop={handleEngineStop}
                linkColor={handleLinkColor}
                linkWidth={handleLinkWidth}
                linkLineDash={handleLinkDash}
                nodeCanvasObjectMode={() => 'replace'}
                nodeCanvasObject={handleNodeCanvas}
              />
            )}
            <div className="toolbarWrap">
              <MapToolbar
                isOpen={isMapOpened}
                handleMapToolbarClick={handleMapToolbarClick}
                handleZoomIn={handleZoomIn}
                handleZoomOut={handleZoomOut}
                handleCenter={handleCenter}
              />
            </div>
          </div>
        </div>

        {/* {isMenuOpen && ( */}
        <div className="menu" style={{ width: menuWidth }}>
          <button 
            className="closeMenuBtn"
            onClick={() => {
                if(isSelectedBySearch) {
                  for(let i = 0; i < isSelectedBySearch.length; ++i) {
                    for(let j = 1; j < isSelectedBySearch[i].length; ++j) {
                      closeNode('cat_' + isSelectedBySearch[i][j].id);
                      closeMenu();
                    }
                  }
                  setIsSelectedBySearch(null)
                }
                closeMenu();
            }}
          >
            <img className="closeMenuBtnImg" src={undo} alt="" />
          </button>
          <div className="menuWrap">
            <div
              className="info"
              style={{ background: LightenDarkenColor(selectedNodeColor ? 
                selectedNodeColor : (currentNode ? 
                  (currentNode.__indexColor === '#10000c' ? '#82007e' : 
                  currentNode.__indexColor) : '#EF4444'), -30) }}
            >
              <div className="infoBreadCrumbs">
                {openedLevel && isMenuOpen
                  ? getSelectedCompanyBreadCrumbs(openedLevel)
                  : ''}
                {/* Category1 &#62; Category 2 */}
              </div>
              <h2 className="infoСategory">
                {openedLevelInfo ? openedLevelInfo.name : ''}
              </h2>
              <div className="infoCompanyAmount">
                {openedLevelInfo ? openedLevelInfo?.child_links?.length : ''}{' '}
                COMPANIES
              </div>
            </div>
            <div className="menuAccordionWrap">
              {openedLevelCompanies &&
                openedLevelCompanies.map((item, index) => (
                  <Collapsible
                    key={index}
                    open={item.open}
                    trigger={
                      <AccordionItemTrigger
                        text={item.name}
                        logo={item?.logo ? BASE_URL + item.logo : ''}
                        flag={item?.flag ? item.flag : ''}
                      />
                    }
                    onOpen={() => onTriggerOpen(item.id)}
                    onClose={() => onTriggerClose(item.id)}
                    transitionTime={200}
                  >
                    <p className="itemText">
                      {item?.about
                        ? item.about
                        : 'Company info Company info Company info Company info Company info Company info Company info Company info Company info Company info Company info Company info'}
                    </p>
                    <div className="linksWrap">
                      { item.website && 
                        <a
                          className="itemLink"
                          href={item.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          >
                          <img className="linkImg" src={website} alt="" />
                        </a>
                      }
                      { item.linked_in_url && 
                        <a
                          className="itemLink"
                          href={item.linked_in_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          >
                          <img
                            className="linkImg linkedin"
                            src={linkedin}
                            alt=""
                          />
                        </a>
                      }
                      
                    </div>
                  </Collapsible>
                ))}
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
      <div className="header">
        <div className="toolBar">
          {/* <button onClick={handleZoomIn}>+</button>
          <button onClick={handleZoomOut}>-</button> */}
          {/* <button
            onClick={() => {
              setMapView('3D');
            }}
          >
            3D
          </button> */}
        </div>
      </div>
      {/* <button className={styles.getScreenShot_button} onClick={getScreenShot}>get screenshot</button> */}
      {/* <div className="searchBar">
          <div className="search">Search</div>
          <button
            onClick={() => {
              setCurrentFlow('findCompany');
              setOpenedLevel(null);
              setSelectedCompanyNodes([24]);
            }}
          >
            Company 24
          </button>

          <button
            onClick={() => {
              setCurrentFlow('findCompany');
              setOpenedLevel(null);
              setSelectedCompanyNodes(['cat_88780']);
            }}
          >
            Company 3
          </button>

          <button
            onClick={() => {
              setCurrentFlow('findCompany');
              setOpenedLevel(null);
              setSelectedCompanyNodes(['comp_3049', 'comp_2739', 'comp_2179']);
            }}
          >
            Companies 19 and 26
          </button>
        </div> */}
    </div>
  );
}
