import React, { Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

const Drawer = ({ visible, onClose, children, width = "max-w-lg" }) => {

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-hidden" open={visible} onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-100 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-100 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`w-screen ${width}`}>
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  { children }
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

}

const Title = (props) => {
  let { onClose, title, description, color } = props;
  return (
    <div className={`py-6 px-4 bg-${color}-700 sm:px-6`}>
      <div className="flex items-center justify-between">
        <Dialog.Title className="text-lg font-medium text-white">{title}</Dialog.Title>
        <div className="ml-3 h-7 flex items-center">
          <button
            type="button"
            className={`bg-${color}-700 rounded-md text-${color}-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white`}
            onClick={onClose}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-1">
        <p className="text-sm text-blue-300">
          {description}
        </p>
      </div>
    </div>
  )
}
export default Object.assign(Drawer, { Title })