import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { RefreshIcon } from '@heroicons/react/solid'
import { fetchMap } from "../actions/mapActions";
import { fetchCompany } from "../actions/companyActions";
import '../stylesheets/tree.css';
import CompanyDetails from './CompanyDetails';
import NetworkMap from './NetworkMap';

const Chart = (props) => { 

  const { mapId, totalWidth, totalHeight, map, company, companyLoading, initialCompanyId, loading, color, boldColor, mainColor, onDetailsClose, selectedCategories } = props;
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [treeTranslate, setTreeTranslate] = useState({ x: 0, y: 0 });
  const treeContainerRef = useRef(null);
  const shouldRecenterTreeRef = useRef(true);

  useEffect(() => {
    props.dispatch(fetchMap(mapId));
  }, [mapId])

  useEffect(() => {
    if (initialCompanyId) {
      props.dispatch(fetchCompany(initialCompanyId, mapId));
      setDetailsVisible(true);
    }
  }, [initialCompanyId])

  const onNodeClick = (node, event) => {
    if (node.type === "COMPANY") {
      props.dispatch(fetchCompany(node.id, mapId));
      setDetailsVisible(true);
    }
  }

  const handleClose = () => {
    setDetailsVisible(false);
    onDetailsClose();
  }

  if (!map || loading) {
    return (
      <div style={{height: totalHeight, width: totalWidth}} className="opacity-50 z-10 flex place-items-center justify-center">
        <RefreshIcon className="h-8 w-8 animate-spin" aria-hidden="true" />
      </div>
    )
  }
  else {

    const data = {
      name: map.title,
      children: map.categories,
      type: 'root',
      val: 20
    }

    // compute the total height
    let computedHeight = 50 * map.categories.length;
    if (computedHeight < 1000) computedHeight = 1000;
    return (
      
      <>
        <div ref={treeContainerRef} id="treeWrapper" style={{ width: 1100, height: 1000 }}>
          <NetworkMap initialDepth={map.initial_depth} data={data} onNodeClick={onNodeClick} mapId={map.id} initialLinkColor={color} initialBoldColor={boldColor} selectedCategories={selectedCategories}/>
        </div>
        <CompanyDetails 
          company={company}
          visible={detailsVisible}
          loading={companyLoading}
          color={mainColor}
          onClose={handleClose}
          />
      </>
      
    )
    
  }
  
}

const mapStateToProps = state => ({
  map: state.maps.map,
  loading: state.maps.loading,
  company: state.companies.company,
  companyLoading: state.companies.loading,
});
export default connect(mapStateToProps)(Chart);
