import React, { useLayoutEffect, useRef } from "react";
import { NetworkMapD3 } from "./NetworkMapD3";
import "../stylesheets/networkmap.css";

let flatten = (children, extractChildren) => Array.prototype.concat.apply(
  children, 
  children.map(x => flatten(extractChildren(x) || [], extractChildren))
);

class NetworkMap extends React.Component {

  extractChildren = x => x.children;

  state = {
    initialized: false,
    flatNodes: [],
    lastInitialNode: null,
  }

  // Handle component mounting event
  componentDidMount() {
    this.createDiagram = this.createDiagram.bind(this)
    this.createDiagram()
  }

  // Handle component update event
  componentDidUpdate(prevProps, prevState) {
    if (this.props.disableUpdate) {
      return
    }
    if (prevProps.mapId && this.props.mapId !== prevProps.mapId) {
      this.createDiagram()
    }
    if (this.props.selectedCategories && this.props.selectedCategories !== prevProps.selectedCategories) {
      this.findAndClickNode();
    }
  }

  findAndClickNode = () => {
    const { selectedCategories } = this.props;
    const { flatNodes, lastInitialNode } = this.state;
    let index = selectedCategories.length - 1;
    let searching = true;
    const futureIds = [];
    let initialNode = null;
    
    while (searching) {
      const node = flatNodes.find(d => d.data.id === selectedCategories[index].id && d.data.type === 'level')
      if (node) {
        initialNode = node;
        searching = false
      } else {
        futureIds.push(selectedCategories[index].id);
        index--;
        if (index < 0) {
          searching = false;
        }
      }
    }
    
    this.clickNode(initialNode);

    futureIds.forEach(id => {
      const { nodesData }  = this.chart.getState();
      const flatNodes = flatten(nodesData, this.extractChildren);
      const node = flatNodes.find(d => d.data.id === id && d.data.type === 'level')
      this.clickNode(node);
    })

    this.setState({ lastInitialNode: initialNode })
  }

  clickNode = node => {
    if (node && node.data.children == null) {
      this.chart.handleNodeClick({ event: null, d: node, state: this.chart.getState() });
    }
  }

    // Render chart
    render() {
        return (
            <div
                style={{ display: this.props.hide ? "none" : "inline-block" }}
                className="d3-network-map d3-chart-container"
            >
                <div ref={(node) => (this.node = node)} />

            </div>
        )
    }

    // Reusable, create diagram function
    createDiagram() {
        // Retrieve node dom element
        const node = this.node

        // If there is no data, exit from component
        if (!this.props.data) {
            return
        }

        // Retrieve props
        const props = this.props

        // Create chart reference

        if (!this.chart) {
            /* eslint-disable */
            this.chart = new NetworkMapD3()
        }

        // Pass chart some parameters and (re)render it
        this.chart
            .container(node)
            .data(props.data, props.initialDepth || 1)

        if (props.svgHeight !== undefined) {
            this.chart.svgHeight(props.svgHeight)
        }

        if (props.marginTop !== undefined) {
            this.chart.marginTop(props.marginTop)
        }

        if (props.marginBottom !== undefined) {
            this.chart.marginBottom(props.marginBottom)
        }

        if (props.marginRight !== undefined) {
            this.chart.marginRight(props.marginRight)
        }

        if (props.marginLeft !== undefined) {
            this.chart.marginLeft(props.marginLeft)
        }
        if (props.normalCircleRadius !== undefined) {
            this.chart.normalCircleRadius(props.normalCircleRadius)
        }

        if (props.rootCircleRadius !== undefined) {
            this.chart.rootCircleRadius(props.rootCircleRadius)
        }
        if (props.initialLinkColor !== undefined) {
            this.chart.initialLinkColor(props.initialLinkColor)
            this.chart.defaultFillColor(props.initialLinkColor)
        }

        if (props.isTree !== undefined) {
            this.chart.isTree(props.isTree)
        }

        if (props.onNodeClick !== undefined) {
            this.chart.onEdgeClick(props.onNodeClick)
        }

        if (props.initialBoldColor !== undefined) {
            this.chart.defaultHoverColor(props.initialBoldColor)
        }


        this.chart
            .render();

        // create the flat map
        const { nodesData }  = this.chart.getState();
        const flatNodes = flatten(nodesData, this.extractChildren);
        
        this.setState({ flatNodes })

    }
}

export default NetworkMap